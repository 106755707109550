<template>
  <div class="text-center">
    <v-dialog
      v-model="openDialog"
      width="500"
      height="500"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>Description Form </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="livestockdesc.animaldesc"
                label="Description"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Farm Gate Price Range
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="livestockdesc.farmgateprice_begin"
                label="Begin"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="livestockdesc.farmgateprice_end"
                label="End"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="sel.length"
            small
            color="info"
            outlined
            rounded
            text
            @click="updateLivestock"
          >
            <v-icon>mdi-content-save</v-icon> Update
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!sel.length"
            small
            outlined
            rounded
            text
            color="success"
            @click="saveNewLivestock"
          >
            <v-icon>mdi-content-save</v-icon>
            Save as New Record
          </v-btn>
          <v-btn
            v-if="sel.length"
            small
            outlined
            rounded
            text
            color="error"
            @click="deleteLivestock"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            text
            @click="closeDialog"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {

    props: ['openDialog', 'sel'],
    data: () => ({
      dialog: false,
      livestockdesc: {
        animaldesc: '',
        animalclass_id: '1',
      },
    }),

    watch: {
      sel: function (val) {
        if (val.length) {
          this.livestockdesc = val[0]
        } else {
          return val
        }
      },
    },

    mounted () {
    // this.livestockdesc.animaldesc=''
    },

    methods: {
      closeDialog () {
        this.$emit('closeDlg', false)
      },
      saveNewLivestock () {
        this.$store.dispatch('livestockdesc/postData', this.livestockdesc)
        this.$toast.success('Save Complete')
        this.closeDialog()
      },
      async deleteLivestock () {
        const res = await confirm('Delete Data')
        if (res) {
          this.$store.dispatch('livestockdesc/deleteData', this.livestockdesc.id)
          this.$toast.success('Delete Complete')
        } else {
          this.$toast.info('Delete Cancelled')
        }
        this.closeDialog()
      },
      async updateLivestock () {
        await this.$store.dispatch(
          'livestockdesc/updateData',
          this.livestockdesc,
        )
        this.$toast.success('Update Complete')
        this.closeDialog()
      },
    },
  }
</script>
