<template>
  <div class="text-center">
    <v-dialog
      v-model="openDialog"
      width="500"
      height="500"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>Variety Form </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="livestockvariety.animalvariety"
                label="Description"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="sel.length"
            small
            color="info"
            outlined
            rounded
            text
            @click="updateLivestock"
          >
            <v-icon>mdi-content-save</v-icon> Update
          </v-btn>
          <v-spacer />

          <v-btn
            v-if="!sel.length"
            small
            outlined
            rounded
            text
            color="success"
            @click="saveNewLivestock"
          >
            <v-icon>mdi-content-save</v-icon>
            Save as New Record
          </v-btn>
          <v-btn
            v-if="sel.length"
            small
            outlined
            rounded
            text
            color="error"
            @click="deleteLivestock"
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            text
            @click="closeDialog"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {

    props: ['openDialog', 'sel'],
    data: () => ({
      dialog: false,
      livestockvariety: {
        animalvariety: '',
      },
    }),

    watch: {
      sel: function (val) {
        if (val.length) {
          this.livestockvariety = val[0]
        } else {
          return val
        }
      },
    },

    mounted () {
    // this.livestockdesc.animaldesc=''
    },

    methods: {
      closeDialog () {
        this.$emit('closeDlg', false)
      },
      saveNewLivestock () {
        this.livestockvariety.animaldesc_id =
          this.$store.state.livestockdesc.selected
        this.$store.dispatch('livestockvariety/postData', this.livestockvariety)
        this.$toast.success('Save Complete')
        this.closeDialog()
      },
      async deleteLivestock () {
        this.livestockvariety.animaldesc_id ==
          this.$store.state.livestockdesc.selected
        const res = await confirm('Delete Data')
        if (res) {
          this.$store.dispatch(
            'livestockvariety/deleteData',
            this.livestockvariety,
          )
          this.$toast.success('Delete Complete')
        } else {
          this.$toast.message('Delete Cancelled')
        }
        this.closeDialog()
      },
      async updateLivestock () {
        await this.$store.dispatch(
          'livestockvariety/updateData',
          this.livestockvariety,
        )
        this.$toast.success('Update Complete')
        this.closeDialog()
      },
    },
  }
</script>
