<template>
  <div>
    <v-row>
      <v-col>
        <livestockdesc />
      </v-col>
      <v-col>
        <livestockvariety />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import livestockdesc from './livestockdesc.vue'
  import livestockvariety from './livestockvariety.vue'

  export default {
    components: {
      livestockdesc,
      livestockvariety,
    },
  }
</script>
